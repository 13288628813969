<template>
  <div>
    <v-card :loading="loading">
      <v-toolbar flat>
        <v-toolbar-title>Varepriser</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn color="primary" :loading="loading" text @click="updatePartPrices">
          Opdater varepriser
          <v-icon right>mdi-calculator</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-alert type="info" text>
          Vær sikker på hvad du laver før du bruger værktøjer du finder her på siden.
        </v-alert>

        <v-alert v-if="result" type="success">
          {{ result }}
        </v-alert>

        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>

        <v-alert v-if="errors.length" type="error" text>
          <h3 class="mb-4">Fejl</h3>

          <v-row v-for="(error, index) in errors" :key="index">
            <v-col>
              <pre><code>{{ index + 1 }}) {{ error }}</code></pre>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'UpdatePartPrices',
  data() {
    return {
      loading: false,
      result: null,
      errorMessage: null,
      errors: []
    }
  },
  methods: {
    updatePartPrices: async function() {
      this.result = null
      this.errorMessage = null
      this.errors = []
      this.loading = true

      try {
        const { data } = await this.$AuthService.api.patch('adminpart/updatepartprices')

        if (data.errors.length) {
          throw data.errors
        }

        else if (!data.success && data.message) {
          throw data.message
        }
        
        else if (!data.success) {
          throw 'An unknown error occurred'
        }

        this.result = data.message
      } catch (error) {
        if (typeof error === 'string') {
          this.errorMessage = error
        }

        else if (Array.isArray(error)) {
          this.errors = error
        }

        console.error(error)
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
